import { Badge, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useChatEnabled } from '@providers/hooks/ChatEnabledHook';
import { useSession } from '@providers/user/SessionContext';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import styles from './BottomNavigation.styles';
import { items } from './items';

const BottomMenu: React.FC = () => {
  const classes = styles();
  const router = useRouter();
  const isCriOS = typeof window !== 'undefined' ? navigator.userAgent.indexOf('CriOS') >= 0 : false;
  const [bottomSelected, setBottomSelected] = React.useState(items.find(item => item.selected(router.pathname))?.redirection);
  const [cookies] = useCookies();
  const { user } = useSession();
  const { enableChat, checkEnableChat } = useChatEnabled();

  const handleChange = (event, newValue) => {
    setBottomSelected(newValue);
    router.push(newValue);
  };

  useEffect(() => {
    checkEnableChat(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);


  const renderIcon = (item) => {
    const Icon = item.icon;

    if (item.badge) {
      return (
        <Badge color='error' badgeContent=' ' variant='dot'>
          <Icon classes={{ root: classes.iconRoot }} />
        </Badge>
      );
    } else {
      return <Icon classes={{ root: classes.iconRoot }} />;
    }
  };

  return (
    <BottomNavigation value={bottomSelected} onChange={handleChange} showLabels className={classes.root}>
      {items
        .filter(item =>
          item.redirection !== '/chataco' ||
          (user && user.isAdmin) ||
          enableChat,
        )
        .map((item) => {
          return (
            <BottomNavigationAction
              label={item.text}
              icon={renderIcon(item)}
              value={item.redirection}
              classes={clsx({ root: classes.itemRoot }, { isCriOS })}
              key={item.redirection}
            />
          );
        })}
    </BottomNavigation>
  );
};

export default BottomMenu;
