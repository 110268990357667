import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from '@theme';
import React from 'react';

const useStyles = makeStyles(() => ({
  main: {
    width: '530px',
    height: '200px',

    [theme.breakpoints.down(1250)]: {
      maxWidth: '416px',
      height: '440px',
    },
  },
}));

export const ExpertsSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='space-around'>
      <Card className={classes.main}>
        <CardHeader
          avatar={<Skeleton animation='pulse' variant='rectangular' height='90px' width='90px'
                            style={{ borderRadius: '8px' }} />}
          title={<>
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
          </>}
        />
        <CardContent>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' justifyContent='space-between' width='450px'>
              <Skeleton animation='pulse' variant='rectangular' component='div' height='34px' width='48%'
                        style={{ borderRadius: '4px' }} />
              <Skeleton animation='pulse' variant='rectangular' component='div' height='34px' width='48%'
                        style={{ borderRadius: '4px' }} />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card className={classes.main}>
        <CardHeader
          avatar={<Skeleton animation='pulse' variant='rectangular' height='90px' width='90px'
                            style={{ borderRadius: '8px' }} />}
          title={<>
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
            <Skeleton animation='pulse' style={{ borderRadius: '6px' }} variant='text'
                      width={350} height={18} />
          </>}
        />
        <CardContent>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' justifyContent='space-between' width='450px'>
              <Skeleton animation='pulse' variant='rectangular' component='div' height='34px' width='48%'
                        style={{ borderRadius: '4px' }} />
              <Skeleton animation='pulse' variant='rectangular' component='div' height='34px' width='48%'
                        style={{ borderRadius: '4px' }} />
            </Box>
          </Box>
        </CardContent>
      </Card>

    </Box>
  );
};
