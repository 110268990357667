import { makeResponsiveStyles, StyleResponsive } from '@providers/Responsive.provider';

export const styles = makeResponsiveStyles(responsive => theme => ({
  list: {
    padding: '0',
  },
  listItem: {
    paddingLeft: '0',
    paddingRight: '0',
    '&.timeline:before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      top: '72px',
      borderRadius: 8,
      width: 3,
      position: 'absolute',
      [responsive.onDesktop]: {
        height: 'calc(100% - 60px)',
        left: '23px',
        top: '70px',
      },
      [responsive.onMobile]: {
        height: 'calc(100% - 58px)',
        left: '16px',
        top: '58px',
      },
    },
  },
  listItemAvatar: {
    [responsive.onDesktop]: {
      minWidth: '56px',
    },
    [responsive.onMobile]: {
      minWidth: '45px',
    },
    boxShadow: '0px 0px 0 8px white',
  },
  listItemText: {
    padding: '5px 10px',
    backgroundColor: 'rgba(38, 166, 91, 0.1)',
    borderRadius: '8px',
    '&.author': {
      backgroundColor: 'unset',
    },
  },
  label: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 400,
    padding: '3px 12px',
    [responsive.onMobile]: {
      fontSize: '10px',
      padding: '3px 5px',
    },
  },
  date: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#828282',
  },

  modalDelete: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '555px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    padding: '16px 19px',

    [StyleResponsive.onMobile]: {
      width: '100%',
      height: '100%',
    },
  },


}));
