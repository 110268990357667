import { Modal, ModalProps } from '@components/modal/Modal';
import { DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsMobile } from '@providers/Responsive.provider';
import { GA } from '@shared/ga/ga';
import DOMPurify from 'dompurify';
import React from 'react';


interface IFormProps {
  title?: string;
  url: string;
  banners?: unknown[];
}

const useStyles = makeStyles({
  content: {
    padding: 0,
    height: '100%',
  },
});

export const GoogleForm: React.VFC<{ modalProps: ModalProps, form: IFormProps }> = ({ modalProps, form }) => {

  const styles = useStyles();
  const isMobile = useIsMobile();

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');
    GA.event({ action: 'exit_modal', category: 'button', label: 'exitModal', value: 1 });
  };

  return (
    <>
      <Modal {...(modalProps as ModalProps)} onClose={handleClose}
             maxWidth='md' fullWidth fullScreen={isMobile}
             sx={{
               '& .MuiDialog-container': { alignItems: 'flex-start' },
               '& .MuiPaper-root': { height: '100%', overflow: 'hidden' },
               '& .MuiBox-root': { height: '100%', padding: '0' },
               height: '100%',
             }}>

        <DialogContent className={styles.content} style={{ marginTop:  isMobile ? '20px' : '15px' }}>
          {form.banners &&
            <div style={{
            height: 'auto',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            }}>
              {form.banners.map((banner) => banner)}
           </div>
          }
          <div style={{ textAlign: 'center', padding: '10px 5px', fontSize: ( isMobile ? 17 : 18), fontWeight: 500 }}
               dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(form.title) }} />
          <div style={{height: '82%'}}>
            <iframe
              title={form.title}
              src={form.url}
              width='100%'
              height='100%'
              frameBorder='0'
            >
              Cargando…
            </iframe>
          </div>
        </DialogContent>
      </Modal>
    </>
);
};
