import RecoverPassword from '@components/layout/Login/RecoverPassword';
import SocialRegister from '@components/layout/Register/SocialRegister.form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useAcoRouter } from '@providers/hooks/AcoRouter';
import { LoginStatus, useLogin } from '@providers/hooks/LoginHook';
import { GA } from '@shared/ga/ga';
import React, { useState } from 'react';

import styles from './LoginForm.styles';

interface Props {
  onContinue?: () => void;
  isModal?: boolean,
  setChange?: (boolean: boolean) => void,
  setClose?: () => void,
  email?: string;
  notRedirect?: boolean;
  fromTicket?: boolean;
}

interface FormInputs {
  email: string;
  password: string;
}

const LoginForm: React.VFC<Props> = (props) => {
  const classes = styles();

  const router = useAcoRouter();

  const INITIAL_FORM_INPUTS = {
    email: props.email ? props.email : '',
    password: '',
  };

  const [values, setValues] = React.useState<FormInputs>(INITIAL_FORM_INPUTS);

  const [showPassword, setShowPassword] = useState(false);

  const [recoverPass, setRecoverPass] = useState(false);

  const enableButton = values.email.length > 3 && values.password.length > 3;

  const { stateLogin, loginError, login } = useLogin();

  const handleChange = (prop: keyof FormInputs) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRecoverPassword = () => {
    setRecoverPass(true);
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      login({ user: values.email, password: values.password, notRedirect: props.notRedirect });
    }
  };

  const handleChangeState = () => {
    setRecoverPass(false);
  };

  const handleLogin = () => {
    login({user: values.email, password: values.password, notRedirect: props.notRedirect})

    if (props.fromTicket) {
      GA.event({ action: 'login_from_modal', category: 'button', label: 'loginFromModal', value: 1 });
    }
  };


  return (recoverPass ? <RecoverPassword changeState={() => handleChangeState()} isModal={props.isModal} /> : (
    <Paper classes={{ root: `${classes.paper} ${props.isModal && 'modal'}` }}>
      <Box className={classes.boxTop}>
        <Box>
          <Typography variant='h5'>Iniciar Sesión</Typography>
        </Box>

        <Box className={classes.boxBottom}>
          <Typography variant='body1'>¿Nuevo en Agroconsultas?</Typography>
          <Button color='primary' disableRipple className={classes.register}
                  onClick={() => props.isModal ? props.setChange(false) : router.register()}>
            Registrate GRATIS
          </Button>
        </Box>

      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <form autoComplete='off' className={classes.form}>
        <Box className={classes.textInputContainer}>
          <TextField
            required
            autoFocus
            id='email'
            label='Usuario o email'
            defaultValue={props.email}
            margin='normal'
            variant='outlined'
            autoComplete='off'
            size='medium'
            error={stateLogin === LoginStatus.ERROR}
            onChange={handleChange('email')}
            inputProps={{
              className: classes.inputEmail,
              autoComplete: 'email',
              form: {
                autoComplete: 'off',
              },
            }}
          />
        </Box>

        <Box className={classes.textInputContainer}>
          <FormControl variant='outlined' required>
            <InputLabel htmlFor='outlined-adornment-password' error={stateLogin === LoginStatus.ERROR}>
              Contraseña{' '}
            </InputLabel>
            <OutlinedInput
              id='password'
              label='Contraseña '
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              autoComplete='off'
              onChange={handleChange('password')}
              error={stateLogin === LoginStatus.ERROR}
              onKeyPress={handleEnterPress}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                    size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                className: classes.inputPassword,
              }}
            />
          </FormControl>
        </Box>
      </form>

      {stateLogin === LoginStatus.ERROR && (
        <FormHelperText id='error-text' className={classes.formHelperText}>
          <ErrorIcon />
          <Typography variant='body1' component='span' className={classes.textFormHelperText}>
            {loginError ? loginError : 'Email o contraseña incorrectas'}
          </Typography>
        </FormHelperText>
      )}

      <Box className={classes.boxButtons}>
        <Box className={classes.boxButtonRecorverPass}>
          <Button color='secondary' disableRipple className={classes.buttonRecorverPass}
                  onClick={handleRecoverPassword}>
            Olvidé mi contraseña
          </Button>
        </Box>

        <Box className={classes.boxButtonLogin}>
          {stateLogin !== LoginStatus.LOADING ? (
            <Button
              size='large'
              disableRipple
              classes={{ root: classes.buttonLogin, disabled: classes.disabled }}
              onClick={() => handleLogin()}
              disabled={!enableButton}>
              Iniciar sesión
            </Button>
          ) : (
            <Box className={classes.circularProgress}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <Box>
        <Box marginTop='8px'>
          <Typography variant='body1' className={classes.boxTop}>
            o
          </Typography>
        </Box>

        <Box className={classes.boxTop}>
          <Box className={classes.boxConnect}>
            <Typography variant='body1'>Conectate con:</Typography>
          </Box>
          <Box>
            <SocialRegister onRegister={props.onContinue} onCloseModal={props.setClose} isModal={props.isModal} />
          </Box>
        </Box>
      </Box>

      {props.isModal && stateLogin === LoginStatus.LOGGED && props.setClose()}
    </Paper>
  ));
};

export default LoginForm;
