import { useRegister } from '@components/layout/Register/context/Register.context';
import { RegisterGeolocationInput } from '@components/layout/Register/GeolocationInput/RegisterGeolocationInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {
  Autocomplete,
  Box,
  BoxTypeMap,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  Select,
  Typography,
  MenuItem,
} from '@mui/material';
import { PaperTypeMap } from '@mui/material/Paper/Paper';
import TextField from '@mui/material/TextField';
import { OverridableComponent } from '@mui/types';
import {useLogin} from '@providers/hooks/LoginHook';
import { OnDesktop, OnMobile, ResponsiveDevice, useResponsive } from '@providers/Responsive.provider';
import { CheckLogin, Occupations } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import esLocale from 'date-fns/locale/es';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import styles from './Register.styles';

interface Props {
  back: () => void;
  isModal?: boolean;
  finishRegister?: (password?: string, fromProvider?: boolean) => void;
  isAutomaticModal?: boolean;
  ticketForm?: boolean;
}

export const RegisterUserProfileForm: React.VFC<Props> = (props) => {
  const classes = styles();
  const responsive = useResponsive();
  const router = useRouter();
  const { login } = useLogin();

  const { data: values, dirty } = useRegister();

  const [step, setStep] = useState<1 | 2>(1);

  const [creating, setCreating] = useState(false);
  const [errorCreate, setErrorCreate] = useState(false);

  const stepOneValid =
    values.name?.length > 3 &&
    (values.provider ? true : values.password?.length > 5 ) &&
    values.birthDate !== undefined &&
    !dirty &&
    values.lastName?.length > 0;

  const stepTwoValid = !!values.login && !!values.location && !!values.occupationId && !dirty;

  const enableButton = step === 1 ? stepOneValid : stepTwoValid;

  const Container: OverridableComponent<PaperTypeMap | BoxTypeMap> =
    responsive === ResponsiveDevice.DESKTOP ? Paper : Box;

  const register = React.useCallback(() => {
    setCreating(true);
    setErrorCreate(false);

    if (props.isAutomaticModal) {
      values._referrer = 'modal-open-ticket';
    }

    axios
      .post('/api/user/register', values)
      .then((res) => {

        if (res.data.state === 'Activo' && values.provider) {
          login({ token: values.token, provider: values.provider });
          props.finishRegister(undefined, true);
        } else {
          window.localStorage.setItem('__tmp_w', JSON.stringify(res.data));

          if (props.isModal) {
            props.finishRegister(values.password);
          }
          else {
            router.push({ pathname: '/', query: { email: res.data.email, name: res.data.name }});
          }
          //router.home(); TODO: ¡comprobar no verificado con '__tmp_w'!
        }
      })
      .catch((err) => {
        console.warn(err);
        setErrorCreate(true);
        setCreating(false);
      });
  }, [login, router, values, props]);

  return (
    <Container className={ classes.paper }>
      <Box className={classes.boxTop}>
        <Box className={classes.boxLogin}>
          <Typography variant='h5'>Unite a la comunidad</Typography>
        </Box>
      </Box>

      <Stack spacing={5} flexGrow={1}>
        {step === 1 && <StepOne />}
        {step === 2 && <StepTwo />}
      </Stack>

      {errorCreate && (
        <Box paddingX={'5px'} width='100%'>
          <Typography color='error'>No se puede crear usuario. Intentalo mas tarde</Typography>
        </Box>
      )}

      <Box className={classes.boxButtons}>
        <Button
          color='secondary'
          size='small'
          onClick={() => {
            if (step === 2) {
              setStep(1);
            } else {
              props.back();
            }
          }}
          disableRipple>
          Volver
        </Button>
        <LoadingButton
          loading={creating}
          size='large'
          disableRipple
          classes={{ root: classes.buttonLogin, disabled: classes.disabled }}
          onClick={() => (step === 1 && stepOneValid ? setStep(2) :  register())}
          disabled={!enableButton}>
          {step === 1 ? 'Continuar' : 'Registrarse'}
        </LoadingButton>
      </Box>
    </Container>
  );
};

/**
 * Contains: name, lastName, birthDate, password
 */
const StepOne: React.VFC = () => {
  const register = useRegister();
  const { data: values, set }  = useRegister();
  const classes = styles();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  useEffect(() => {
    setShowPasswordError(values.password?.length < 6)
  }, [values.password])

  return (
    <>
      <TextField
        required
        autoComplete='off'
        label='Nombre'
        value={values.name}
        color='secondary'
        variant='outlined'
        autoFocus
        onChange={(e) => set('name', e.target.value)}
      />

      <TextField
        required
        autoComplete='off'
        label='Apellido'
        value={values.lastName}
        color='secondary'
        variant='outlined'
        onChange={(e) => set('lastName', e.target.value)}
      />

      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <OnDesktop>
          <DesktopDatePicker
            label='Fecha de Nacimiento *'
            inputFormat='dd/MM/yyyy'
            openTo='year'
            //date={new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 30))}
            maxDate={new Date(new Date().setUTCFullYear(new Date().getUTCFullYear()))}
            value={values.birthDate}
            onChange={(date) => set('birthDate', date)}
            InputAdornmentProps={{ style: { marginRight: 0 } }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  color='secondary'
                  className={clsx(classes.dateTextField, classes.textFieldSecondary)}
                />
              );
            }}
          />
        </OnDesktop>
        <OnMobile>
          <MobileDatePicker
            label='Fecha de Nacimiento'
            inputFormat='dd/MM/yyyy'
            openTo='year'
            //date={new Date(new Date().setUTCFullYear(new Date().getUTCFullYear() - 30))}
            maxDate={new Date(new Date().setUTCFullYear(new Date().getUTCFullYear()))}
            value={values.birthDate}
            onChange={(date) => set('birthDate', date)}
            InputAdornmentProps={{ style: { marginRight: 0 } }}
            renderInput={(params) => {
              return <TextField {...params} className={clsx(classes.dateTextField, classes.textFieldSecondary)} />;
            }}
          />
        </OnMobile>
      </LocalizationProvider>

      <FormControl required>
        <InputLabel id="gender-select-label">Género</InputLabel>
        <Select
          labelId="gender-select-label"
          id="gender-select"
          value={values.gender}
          label="Género"
          onChange={(e) => set('gender', e.target.value)}
          color="secondary"
          size="medium"
        >
          <MenuItem value={1}>Masculino</MenuItem>
          <MenuItem value={2}>Femenino</MenuItem>
          <MenuItem value={3}>No definido</MenuItem>
        </Select>
      </FormControl>

      { register.showPasswordInput &&
      <FormControl variant='outlined' required>
        <InputLabel color='secondary' error={showPasswordError}>Contraseña</InputLabel>
        <OutlinedInput
          color='secondary'
          value={values.password}
          type={showPassword ? 'text' : 'password'}
          autoComplete='off'
          label='Password'
          onChange={(e) => set('password', e.target.value)}
          error={showPasswordError}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setShowPassword((show) => !show)}
                edge='end'>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
          <Box paddingLeft='5px' minHeight='24px'>
            {showPasswordError &&
                <Typography color='error'> Debe contener al menos 6 caracteres</Typography>}
          </Box>
      </FormControl>}
    </>
  );
};

/**
 * Contains: login, professionId, location
 *
 */
export const StepTwo = () => {
  const { data: values, set } = useRegister();
  const classes = styles();
  const [occupations, setOccupations] = useState<Occupations[]>();
  const [loginValue, setLoginValue] = useState<string>();
  const [loadingOccupations, setLoadingOccupations] = useState(true);
  const [loginAlreadyExists, setLoginAlreadyExists] = useState(false);
  const [validatingLogin, setValidatingLogin] = useState(false);

  useEffect(() => {
    if (!values.email) {
      return;
    }

    const login = values.email.replace(/@.*/, '');
    setValidatingLogin(true);

    axios
      .get<CheckLogin>('/api/user/check-login', { params: { login } })
      .then((res) => {
        set('login', res.data.suggested);
        setLoginValue(res.data.suggested);
      })
      .finally(() => {
        setValidatingLogin(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email]);

  const fetch = React.useMemo(() => {
    return () =>
      axios
        .get<{ occupations: Occupations[] }>('/api/user/occupations')
        .then((res) => {
          setOccupations(res.data.occupations || []);
        })
        .catch((err) => {
          console.warn('Cannot fetch occupations', err);
          setOccupations([]);
        })
        .finally(() => {
          setLoadingOccupations(false);
        });
  }, []);

  useEffect(() => {
    fetch().catch((err) => console.warn('Cannot fetch occupations', err));
    //eslint-disable-next-line
  }, []);

  const loginValidator = React.useMemo(
    () => (login: string) => {
      if (!!login && login.length === 0) {
        return;
      }

      setLoginAlreadyExists(false);

      axios
        .get<CheckLogin>('/api/user/check-login', { params: { login } })
        .then((res) => res.data)
        .then((checked) => {
          if (!checked.valid) {
            setLoginAlreadyExists(true);
            set('login', undefined);
          } else {
            set('login', login);
          }
        })
        .catch((err) => console.warn('Cannot check login', err));
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <FormControl variant='outlined' required>
        <TextField
          required
          disabled={validatingLogin}
          label={validatingLogin ? 'Generando sugerencia...' : 'Login'}
          variant='outlined'
          color='primary'
          InputLabelProps={{ shrink: !!loginValue }}
          value={loginValue || ''}
          error={loginAlreadyExists}
          className={classes.loginTextField}
          onChange={(e) => {
            loginValidator(e.target.value || '');
            setLoginValue(e.target.value || '');
          }}
        />

        {loginAlreadyExists && (
          <Typography style={{ fontSize: '10px' }}>
            El login <b>{values.login}</b> ya esta utilizado por otro usuario
          </Typography>
        )}
        {!loginAlreadyExists && (
          <Typography style={{ fontSize: '10px' }}>
            Otros usuarios podran mencionarte escribiendo <b>@{values.login}</b>
          </Typography>
        )}
      </FormControl>

      <Autocomplete
        loading={loadingOccupations}
        options={occupations}
        defaultChecked={false}
        onFocus={() => occupations.length === 0 && fetch()}
        getOptionLabel={(occupation) => (typeof occupation === 'string' ? occupation : occupation.name)}
        noOptionsText='Cargando ocupaciones...'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(e, value) => set('occupationId', !!value && typeof value !== 'string' ? value.id : undefined)}
        renderInput={(params) => (
          <TextField
            {...params}
            size='medium'
            color='secondary'
            label='Ocupación'
            placeholder='Ocupación'
          />
        )}
      />

      <RegisterGeolocationInput
        onSelect={(location) => set('location', location)}
        onErase={() => set('location', undefined)}
      />
    </>
  );
};
