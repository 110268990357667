import { PayPalButtons } from '@paypal/react-paypal-js';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { OnlineEvent } from '@service/model';
import axios from 'axios';
import { useRouter } from 'next/router';
import React from 'react';

interface PayPalFormProps {
  onlineEvent?: OnlineEvent;
  handleClose?: (closeModal: boolean) => void,
  updateCredits?: (credits: number) => void,
  credits?: number,
  packId?: number,
  packPrice?: string,
  packAmount?: number,
}

const PayPalPaymentForm: React.VFC<PayPalFormProps> = (props) => {

  const router = useRouter();
  const { snackbars } = useCustomSnackbar();

  return (
    <PayPalButtons
      style={{
        shape: 'pill',
        color: 'silver', //change the default color of the buttons
        layout: 'vertical', //default value. Can be changed to horizontal
      }}
      createOrder={async () => {
        try {
          const accessTokenResponse = await axios.get('/api/paypal/accessToken');
          const accessToken = accessTokenResponse.data;

          const response = await axios.post('/api/paypal/orders', {
            cart: [
              {
                quantity: 1,
                price: props.onlineEvent ? props.onlineEvent.settings.dolarAmount : props.packPrice,
              },
            ],
            accessToken: accessToken,
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const orderData = response.data;

          if (orderData.id) {
            return orderData.id;
          } else {
            const errorDetail = orderData?.details?.[0];

            const errorMessage = errorDetail
              ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
              : JSON.stringify(orderData);

            throw new Error(errorMessage);
          }
        } catch (error) {
          console.error(error);
          // setMessage(`Could not initiate PayPal Checkout...${error}`);
        }
      }}
      onApprove={async (data, actions) => {
        try {
          const accessTokenResponse = await axios.get('/api/paypal/accessToken');
          const accessToken = accessTokenResponse.data;

          // Hacer la solicitud POST a la API de PayPal
          const response = await axios.post('/api/paypal/approveOrder', {
            accessToken: accessToken,
            orderID: data.orderID,
            object: props.onlineEvent ? 'Evento::Online' : 'Pack::Chat',
            id: props.onlineEvent ? props.onlineEvent.id : props.packId,
            description: props.onlineEvent ? `Evento Online ${props.onlineEvent.id} | ${props.onlineEvent.title}` : `Pago chats | Pack ${props.packId} `,
          }, {
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const orderData = response.data;

          // Tres casos a manejar:
          //   (1) INSTRUMENT_DECLINED recuperable -> llamar a actions.restart()
          //   (2) Otros errores no recuperables -> Mostrar un mensaje de fallo
          //   (3) Transacción exitosa -> Mostrar confirmación o mensaje de agradecimiento

          const errorDetail = orderData?.details?.[0];

          if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
            // (1) INSTRUMENT_DECLINED recuperable -> llamar a actions.restart()
            // estado recuperable, según https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
            return actions.restart();
          } else if (errorDetail) {
            // (2) Otros errores no recuperables -> Mostrar un mensaje de fallo
            snackbars.showError('Transacción declinada. Intente otro método de pago.');
            throw new Error(
              `${errorDetail.description} (${orderData.debug_id})`,
            );
          } else {
            // (3) Transacción exitosa -> Mostrar confirmación o mensaje de agradecimiento
            // O ir a otra URL: actions.redirect('thank_you.html');
            const transaction = orderData.purchase_units[0].payments.captures[0];

            /* setMessage(
              `Transaction ${transaction.status}: ${transaction.id}. See console for all available details`,
            ); */

            if (props.onlineEvent) {
              snackbars.showSuccess('Pago exitoso. Redirigiendo a la página del evento...');
              const targetUrl = `/events/${props.onlineEvent.id}`;
              router.pathname !== targetUrl ? router.push(`/events/${props.onlineEvent.id}`) : router.reload();
            } else {
              snackbars.showSuccess('Pago exitoso. Se acreditaron tus interacciones.');
              props.updateCredits(props.credits + props.packAmount);
              props.handleClose(false);
            }

           /* console.log(
              'Capture result',
              orderData,
              JSON.stringify(orderData, null, 2),
            );*/
          }
        } catch (error) {
          console.error(error);

          /* setMessage(
             `Sorry, your transaction could not be processed...${error}`,
           );*/
        }
      }}
    />
  );
};

export default PayPalPaymentForm;
