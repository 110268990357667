import { Typography } from '@mui/material';
import React from 'react';

const ErrorNode: React.FC = () => {
  return (
    <Typography variant='h5' color='error'>
      Error al cargar los datos, intenta nuevamente mas tarde.
    </Typography>
  )
}

export default ErrorNode;