import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import {RecoverPasswordResult} from '@service/recoverPassword.service';
import axios from 'axios';
import React, {useState} from 'react';

interface IResponseBack {
  response: string;
  error: boolean;
}

export const useRecoverPassword = () => {

  const [stateSendPassword, setSendPassword] = useState(false);

  const [responseBack, setResponseBack] = React.useState<IResponseBack>();

  const { snackbars } = useCustomSnackbar();

  const sendEmail = async (email) => {

    setSendPassword(true)

    return await axios.post<RecoverPasswordResult>('/api/recoverPassword', email)
        .then((res) => {
          setResponseBack({response: res.data.response, error: res.data.errors});
        })
        .catch(() => {
          snackbars.showError('Error interno');
        })
        .finally(() => setSendPassword(false));
  }

  return {stateSendPassword: stateSendPassword, responseBack: responseBack, sendEmail};
}