import Button from '@components/AcoButtons/Button';
import NextOnlineEventHomeCard from '@components/OnlineEvent/cards/NextOnlineEventHomeCard';
import OnLiveOnlineEventHomeCard from '@components/OnlineEvent/cards/OnLiveOnlineEventHomeCard';
import PastOnlineEventHomeCard from '@components/OnlineEvent/cards/PastOnlineEventHomeCard';
import styles from '@components/Profile/Profile.styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Typography, useTheme, MobileStepper } from '@mui/material';
import Fade from '@mui/material/Fade';
import { useEventCarouselContext } from '@providers/onlineEvent/eventCarouselContext';
import { OnDesktop, OnMobile, useIsMobile } from '@providers/Responsive.provider';
import { OnlineEvent } from '@service/model';
import axios from 'axios';
import React, { PropsWithChildren } from 'react';
import SwipeableViews from 'react-swipeable-views';
import useSWR from 'swr';


export interface OnlineEventHomeProps {
  smallOnLiveEvent?: boolean;
  onliveEvent?: OnlineEvent;
}

const OnlineEventHome: React.FC<PropsWithChildren<OnlineEventHomeProps>> = ({ smallOnLiveEvent }) => {

  const classes = styles();

  const isMobile = useIsMobile();
  const AutoPlaySwipeableViews = SwipeableViews;
  const theme = useTheme();

  const { handleNext, handleBack, activeStepIndex, updateStepIndex } = useEventCarouselContext();

  const handleStepChange = (step) => {
    updateStepIndex(step);
  };


  const url = '/api/events';
  const fetcher = (url: string) => axios.get<{ onlineEvents: OnlineEvent[] }>(url).then((res) => res.data);
  const { data, error } = useSWR(`${url}?category=inHome`, fetcher);

  if (error)
    return (<></>);

  function isLiveFilter(): (file: OnlineEvent) => boolean {
    return file => file.live;
  }

  function isFinishedFilter(): (file: OnlineEvent) => boolean {
    return file => file.finished;
  }

  function isNextFilter(): (file: OnlineEvent) => boolean {
    return file => !file.live && !file.finished;
  }

  const live = data && data.onlineEvents?.filter(isLiveFilter());
  const finish = data && data.onlineEvents?.filter(isFinishedFilter());
  const next = data && data.onlineEvents?.filter(isNextFilter());

  const maxSteps = data && (isMobile ? data.onlineEvents?.length : data.onlineEvents?.length - live?.length);

  const desktop = (
    <>
      {live && live.length > 0 &&
        <Box marginTop='5px'>
          <Typography style={{ marginLeft: isMobile ? '5px' : '0px' }}>Eventos en vivo</Typography>
          {live.map((onLiveEvent, index) => {
              return (
                <OnLiveOnlineEventHomeCard onliveEvent={{ ...onLiveEvent }} key={index}
                                           smallOnLiveEvent={smallOnLiveEvent} />);
            },
          )}
        </Box>
      }

      {next && finish && (next.length > 0 || finish.length > 0) && <Box>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStepIndex}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {next && next.map((onlineEvent, index) => (
            <div key={index}>
              <Typography>Proximos eventos</Typography>
              <OnDesktop>
                <NextOnlineEventHomeCard onliveEvent={{ ...onlineEvent }} key={index}
                                         smallOnLiveEvent={smallOnLiveEvent} />
              </OnDesktop>
            </div>
          ))}

          {finish && finish.reverse().map((onlineEvent, index) => (
            <div key={index}>
              <Typography>Otros eventos</Typography>
              <OnDesktop>
                <PastOnlineEventHomeCard onliveEvent={{ ...onlineEvent }} key={index}
                                         smallOnLiveEvent={smallOnLiveEvent} />
              </OnDesktop>
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {maxSteps && <MobileStepper
          steps={maxSteps}
          position='static'
          variant='dots'
          activeStep={activeStepIndex}
          style={{
            backgroundColor: '#FFFFFF', borderRadius: '0 0 8px 8px',
            borderBottom: '1px solid #DCDCDC',
          }}
          sx={{'& .MuiMobileStepper-dots': {maxWidth: '270px'}}}
          nextButton={
            <Button style={{ minWidth: '10px', padding: '8px 0px' }} size='small' onClick={handleNext}
                    disabled={activeStepIndex === maxSteps - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button style={{ minWidth: '10px', padding: '8px 0px' }} size='small' onClick={handleBack}
                    disabled={activeStepIndex === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          }
        />}
      </Box>}
    </>
  );


  const mobile = (
    <>
      <Box className={classes.boxDots}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStepIndex}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {live && live.map((onlineEvent, index) => (
            <div key={index}>
              {Math.abs(activeStepIndex - index) <= 2 ? (
                <>
                  <OnLiveOnlineEventHomeCard onliveEvent={{ ...onlineEvent }} key={index}
                                             smallOnLiveEvent={smallOnLiveEvent} />
                </>
              ) : null}
            </div>
          ))}

          {next && next.map((onlineEvent, index) => (
            <div key={index}>
              {Math.abs(activeStepIndex - index) <= 2 ? (
                <>
                  <NextOnlineEventHomeCard onliveEvent={{ ...onlineEvent }} key={index}
                                           smallOnLiveEvent={smallOnLiveEvent} />
                </>
              ) : null}
            </div>
          ))}

          {finish && finish.map((onlineEvent, index) => (
            <div key={index}>
              <>
                <PastOnlineEventHomeCard onliveEvent={{ ...onlineEvent }} key={index}
                                         smallOnLiveEvent={smallOnLiveEvent} />
              </>
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {maxSteps && <MobileStepper
          steps={maxSteps}
          position='static'
          variant='dots'
          activeStep={activeStepIndex}
          style={{
            justifyContent: 'center', backgroundColor: '#FFFFFF', borderRadius: '0 0 8px 8px',
            borderBottom: '1px solid #DCDCDC',
          }}
          nextButton={
            <Button size='small' onClick={handleNext} disabled={activeStepIndex === maxSteps - 1}
                    style={{ display: 'none' }}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStepIndex === 0} style={{ display: 'none' }}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />}
      </Box>
    </>
  );

  return (
    <Fade in timeout={1000}>
      <div>
        <OnDesktop>
          <div>{desktop}</div>
        </OnDesktop>
        <OnMobile>
          <div>{mobile}</div>
        </OnMobile>
      </div>
    </Fade>
  );

};

export default OnlineEventHome;
