import { Modal, ModalProps } from '@components/modal/Modal';
import { DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useIsMobile } from '@providers/Responsive.provider';
import { GA } from '@shared/ga/ga';
import { BackgroundSVG, ButtonConsultSVG } from '@svg/modals';
import { useRouter } from 'next/router';
import React from 'react';

const useStyles = makeStyles(() => ({
  content: {
    position: 'relative',
    padding: 0,
    background: '#27a55b',
  },
  overlayText: {
    position: 'absolute',
    top: '10%',
    left: '8%',
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  overlayTextSubtitle: {
    position: 'absolute',
    top: '20%',
    left: '8%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  svgContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  backgroundSVG: {
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  buttonSVG: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));


export const InvitationToConsult: React.VFC<{ modalProps: ModalProps }> = ({ modalProps }) => {

  const styles = useStyles();
  const isMobile = useIsMobile();
  const router = useRouter();

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');
    GA.event({ action: 'exit_modal', category: 'button', label: 'exitModal', value: 1 });
  };

  return (
    <>
      <Modal {...modalProps} onClose={handleClose} maxWidth='md' fullScreen={isMobile}
             sx={{
               '& .MuiDialog-container': { alignItems: 'flex-start' },
               '& .MuiPaper-root': { height: 'auto', overflow: 'hidden' },
               '& .MuiBox-root': { height: '100%', padding: '0' },
               height: '100%',
             }}>
        <DialogContent className={styles.content}>
          <div className={styles.svgContainer}>
            <BackgroundSVG className={styles.backgroundSVG} />
            <a style={{ cursor: 'pointer' }} onClick={(e) => {
              e.stopPropagation();

              router.push({ pathname: '/tickets/new', query: { trainings: true } });

              modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');
            }}>
              <ButtonConsultSVG className={styles.buttonSVG} />
            </a>
          </div>
          <div className={styles.overlayText}>
            ¡Bienvenida a Agroconsultas!
          </div>
          <div className={styles.overlayTextSubtitle}>
            ¿Sabías que por realizar una consulta adquirís acceso gratuito a una capacitación?
          </div>
        </DialogContent>
      </Modal>
    </>
  );
};
