import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingNode: React.FC = () => {
  return (
    <Box height='100%' display='flex' alignItems='center' justifyContent='center'>
      <CircularProgress
        style={{
          marginTop: '20px',
          color: '#26A65B',
        }}
      />
    </Box>
  );
};

export default LoadingNode;