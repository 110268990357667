import styles from '@components/CommunityUserCard/CommunityUserCard.styles';
import { COMMUNITY_TYPE } from '@components/forms/experts/ExpertsForm';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Tooltip } from '@mui/material';
import { onClickPrevent } from '@providers/helper/click';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ThematicExpert } from '@service/model';
import { GA } from '@shared/ga/ga';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

interface RenderThematicsProps {
  thematics: ThematicExpert[];
  user: { id: number, type: COMMUNITY_TYPE, state: string, url: string };
  onNewTicket?: boolean;
  onProfile?: boolean;
}

const RenderThematics: React.FC<RenderThematicsProps> = (props) => {
  const classes = styles();

  const { user: loggedUser } = useSession();
  const loggedUserVerified = loggedUser && loggedUser.verified;
  const router = useRouter();
  const isMobile = useIsMobile();
  const [loginOpen, setLoginOpen] = useState(false);
  const [openTooltipThematics, setOpenTooltipThematics] = useState(false);


  if (!props.thematics || props.thematics.length <= 0) return <></>;


  const renderThematicsChildrens = (item, level) => {
    return (
      <div key={item.id} style={{ marginLeft: `${level * 12}px` }}>
        <Chip
          size='small'
          label={item.name}
          className={classes.chip}
          onClick={(e) => {
            e.stopPropagation();

            if (props.user.state !== 'Inactivo') {
              router.push({
                pathname: '/tickets/new',
                query: {
                  thematic: 'thematicID',
                  thematicID: item.id,
                  type: props.user.type,
                  id: props.user.id,
                },
              });

              GA.event({ action: 'Inició consulta desde tematica', category: 'button', label: '', value: 1 });
            }
          }}
        />
      </div>
    );
  };

  const showThematicsChildrens = (thematics, level = 1) => {
    const elements = [];

    thematics.forEach((item) => {
      elements.push(renderThematicsChildrens(item, level));

      if (item.childrens && item.childrens.length > 0) {
        const childElements = showThematicsChildrens(item.childrens, level + 1);
        elements.push(...childElements);
      }
    });

    return elements;
  };

  const renderThematicChip = (thematic, index) => (
    <>
      <Chip
        key={index}
        size='medium'
        label={thematic.name}
        className={classes.chip}
        onClick={(e) => {
          e.stopPropagation();

          if (!props.onNewTicket && !loggedUserVerified) {
            if (props.user.state !== 'Inactivo') {
              router.push({
                pathname: '/tickets/new',
                query: {
                  thematic: 'thematicID',
                  thematicID: thematic.id,
                  type: props.user.type,
                  id: props.user.id,
                },
              });

              GA.event({ action: 'Inició consulta desde tematica', category: 'button', label: '', value: 1 });

            }
          }
        }}
      />

    </>
  );

  const renderTooltip = (thematic, index) => (
    <>
      <Chip
        key={index}
        size='medium'
        label={thematic.name}
        className={`${classes.chip} main`}
        onClick={(e) => {
          e.stopPropagation();

          if (!props.onNewTicket && !loggedUserVerified) {
            if (props.user.state !== 'Inactivo') {
              router.push({
                pathname: '/tickets/new',
                query: {
                  thematic: 'thematicID',
                  thematicID: thematic.id,
                  type: props.user.type,
                  id: props.user.id,
                },
              });

              GA.event({ action: 'Inició consulta desde tematica', category: 'button', label: '', value: 1 });

            }
          }
        }}
      />

      {showThematicsChildrens(thematic.childrens)}
    </>
  );

  return (
    <>
      <Box width={props.onProfile ? 'unset' : '100%'} display='flex' justifyContent='center'>
        <Tooltip
          key={1}
          open={openTooltipThematics}
          onOpen={() => setOpenTooltipThematics(true)}
          onClose={() => setOpenTooltipThematics(false)}
          placement={isMobile ? 'bottom' : 'right'}
          arrow
          title={props.thematics.map((thematic, index) => renderTooltip(thematic, index))}
          classes={classes}
          leaveTouchDelay={10000}
        >

          <Button
            sx={{
              width: '100%',
              padding: 0,
              margin: '5px 5px 0 5px',
              textTransform: 'none',
              color: '#26A65B',
            }}
            {...onClickPrevent((e) => {
              e.stopPropagation();
              isMobile && setOpenTooltipThematics(!openTooltipThematics);
            })}
            endIcon={openTooltipThematics ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
          >
            Ver temas sobre los que responde

          </Button>
        </Tooltip>
      </Box>


      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                loginModalProps={{ isAutomaticModal: false, viewLogin: true }} />}
    </>
  );
};

export default RenderThematics;
