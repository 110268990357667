import styles from '@components/OnlineEvent/PaymentEvents/PaymentEvent.styles';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box, Typography } from '@mui/material';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { OnlineEvent } from '@service/model';
import { parseDateFromBackendFormat } from '@shared/helper/date';
import DOMPurify from 'dompurify';
import React from 'react';


export const InfoEvent: React.FC<{ event: OnlineEvent, shoyMoneyArg: boolean, hasCoupon: boolean }> = ({ event, shoyMoneyArg, hasCoupon }) => {
  const classes = styles();
  const { user } = useSession();
  const isMobile = useIsMobile();

  const date = parseDateFromBackendFormat(event.date);
  const dateString = date.toLocaleDateString('es-ES', { month: 'long', day: 'numeric' });
  const startTime = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

  return (
    <>
      <Typography variant={isMobile ? 'h6' : 'h4'}>Pago para el ingreso al evento online <b>{event.title}</b></Typography>

      <Box className={classes.boxInfo}>
        <Typography><b>INFO</b></Typography>
        {!event.finished && <Typography>Inicia el {dateString} desde las {startTime} horas.</Typography>}
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.description) }} />
      </Box>

      {event.settings.amount && <Box display='flex' marginBottom='10px'>
        <WarningAmberOutlinedIcon />
        <Typography variant='subtitle1'>Para poder ingresar
          a{event.categoryId == 2 ? 'l evento técnico' : ' la capacitación'} tenes que
          realizar un pago de {user?.country?.includes('Argent') || shoyMoneyArg ? <b>{event.settings.amount} $ARS</b> :
            <b>{event.settings.dolarAmount} USD</b>} {hasCoupon && 'o utilizar tu cupón'}</Typography>
      </Box>}
    </>
  );
};
