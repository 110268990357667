import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

export const useChatEnabled = () => {

  const [cookies, setCookie] = useCookies();
  const [enableChat, setEnableChat] = useState(false);
  const { snackbars } = useCustomSnackbar();

  const checkEnableChat = (showSnack) => {

    if (cookies['Agroconsultas::ChatEnabled']) {
      setEnableChat(true);
    } else {
      axios
        .get<ResponseResult>('/api/user/chatEnabled')
        .then((res) => {
          if (res.data.success) {
            const date = new Date();
            date.setDate(date.getDate() + 365);
            setCookie('Agroconsultas::ChatEnabled', 1, { expires: date });
          } else {
            showSnack && snackbars.showError(res.data.error);
          }
        })
        .catch((err) => {
          console.warn('Cannot check enabled chat', err);
          snackbars.showError('Ocurrio un error interno.');
        });
    }
  };

  return { checkEnableChat, enableChat };
};
