import Button from '@components/AcoButtons/Button';
import { useFinishRegisterStyles } from '@components/modal/FinishRegister/FinishRegister.styles';
import { Modal, ModalProps } from '@components/modal/Modal';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { ResendEmail } from '@service/model';
import { GA } from '@shared/ga/ga';
import axios from 'axios';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import { useCookies } from 'react-cookie';


export const ChatInvitation: React.VFC<ModalProps> = (props) => {

  const classes = useFinishRegisterStyles();
  const { snackbars } = useCustomSnackbar();
  const [cookies, setCookie] = useCookies();
  const router = useRouter();

  const handleClose = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');
    router.push({ pathname: '/' }, undefined, { shallow: true });
    GA.event({ action: 'close_chat_invitation_modal', category: 'modal', label: 'closeModal', value: 1 });
  };

  const handleRedirectChat = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');
    GA.event({ action: 'redirect_to_chat_button', category: 'button', label: 'clickButton', value: 1 });

    axios
      .get<ResendEmail>('/api/chatAco/enable')
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Gracias por formar parte de Chat ACO.');
          const date = new Date();
          date.setDate(date.getDate() + 365);
          setCookie('Agroconsultas::ChatEnabled', 1, { expires: date });
          router.push('/chataco');
        } else {
          snackbars.showError('Por favor escriba al mail de contacto.');
        }
      })
      .catch((err) => {
        console.warn('Cannot check email for user', err);
        snackbars.showError('No pudimos enviar el email');
      });
  };


  return (
    <>
      <Modal {...(props as ModalProps)} onClose={handleClose}
             maxWidth='sm' fullWidth
             sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent className={classes.content}>
          <Typography variant='h4' color='#26A65B' marginBottom='20px'>Probá gratis la inteligencia artificial de
            Agroconsultas</Typography>

          <Typography fontSize='18px' display='inline'>Lanzamos Chat ACO, un asistente de
            inteligencia artificial para que puedas resolver dudas simples de manera inmediata. </Typography>

          <Typography variant='subtitle1' fontSize='18px'>Te elegimos para que lo pruebes
            gratis.</Typography>

        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                  onClick={handleClose}>
            No estoy interesado
          </Button>
          <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                  onClick={handleRedirectChat}>
            Probar Chat ACO
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};
