import Button from '@components/AcoButtons/Button';
import LoadingNode from '@components/common/LoadingNode';
import { CommunityUserCardProps } from '@components/CommunityUserCard/CommunityUserCard';
import TextCard from '@components/ticket/cards/TextCard';
import { Box, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useIsMobile } from '@providers/Responsive.provider';
import { TicketStatus, useNewTicketContext } from '@providers/ticket/NewTicketContext';
import { useSession } from '@providers/user/SessionContext';
import { OnlineEvent, Thematic } from '@service/model';
import { ResponseResult } from '@service/user.service';
import { GA } from '@shared/ga/ga';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';


export const CreateTicketTraining: React.FC<{ event: OnlineEvent }> = ({ event }) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [enableButton, setEnableButton] = React.useState<boolean>(false);
  const [showErrors, setShowErrors] = React.useState({ location: false, text: false });
  const { user, isLogged } = useSession();
  const { snackbars } = useCustomSnackbar();

  const {
    updateThematic,
    updateExpert,
    updateExpertID,
    updateSubcategory,
    updateThematicID,
    updateStepIndex,
    updateTicketText,
    updateTicketLocation,
    removeTicketLocation,
    checkData,
    createTicket,
    ticketText,
    ticketLocation,
    ticketsErrors,
    uploadFilesStore,
    stateTicket,
  } = useNewTicketContext();

  useEffect(() => {
    setEnableButton(checkData(false) && !user?.isAdmin && user && isLogged);
  }, [checkData, enableButton, user, isLogged]);

  useEffect(() => {
    if (stateTicket === TicketStatus.OK) {
      actionByTicketState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTicket]);

  useEffect(() => {
    axios.get<{ expert: CommunityUserCardProps }>('/api/community/experts/40').then((res) => {
      if (res.data.expert) {
        updateExpert(res.data.expert);
        updateExpertID(40);
      }
    });

    axios.get<{ thematic: Thematic }>('/api/thematics/1000000000?thematicDefault=true').then((res) => {
      if (res.data.thematic) {
        updateThematic(res.data.thematic);
        updateThematicID(res.data.thematic.id);
        updateSubcategory(res.data.thematic);
        updateStepIndex(3);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleControlledState = () => {

    if (enableButton) {
      actionByTicketState();
    }

    setShowErrors({
      text: ticketText.length < 50,
      location: ticketLocation == null,
    });
  };

  const handlePaymentTicket = () => {
    GA.event({
      action: `Consultó desde: Evento ${event.id}`,
      category: 'button',
      label: '',
      value: 1,
    });

    axios.post<ResponseResult>('/api/trainings/paymentTicket', { eventId: event.id })
      .then((response) => {
        if (response.data.success) {
          router.push(`/events/${event.id}`);
        } else {
          snackbars.showError('Hubo un error al procesar la consulta');
        }
      }).catch((e) => {
      console.error(e);
    });

  };


  useEffect(() => {
    setShowErrors({
      text: showErrors.text != false && ticketText.length < 50,
      location: showErrors.location != false && ticketLocation == null,
    });
  }, [showErrors.text, showErrors.location, ticketLocation, ticketText]);

  const actionByTicketState = () => {

    switch (stateTicket) {
      case TicketStatus.INITIAL:
        return (createTicket(user));
      case TicketStatus.ERROR:
        return (/*snackbars.showError('Error al crear la consulta')*/ createTicket(user));
      case TicketStatus.LOADING:
        return (<LoadingNode />);
      case TicketStatus.OK:
        return (handlePaymentTicket());

      default:
        throw console.log(`Invalid community type: ${stateTicket}`);
    }
  };

  return (
    <Box>

      <Box display='flex' flexDirection='column' justifyContent='space-around' height={isMobile ? 'unset' : '140px'}>
        <Typography variant={isMobile ? 'h6' : 'h5'}><b>Para ingresar a la capacitación, por favor deja una consulta
          para uno de nuestros expertos.</b></Typography>

        <Typography variant={isMobile ? 'subtitle1' : 'h6'}>Debe ser una consulta puntual (no general) que tengas sobre
          alguna problemática a resolver.</Typography>

        <Typography variant={isMobile ? 'subtitle1' : 'h6'}>Ejemplo: ¿Qué variedades, fechas de siembra y densidades me
          recomiendan para un cultivo de trigo a sembrar en el sur de Santa Fé? </Typography>

      </Box>

      <Box padding='18px'>
        <TextCard TextCardProps={{
          updateText: updateTicketText,
          updateLocation: updateTicketLocation,
          removeLocation: removeTicketLocation,
          text: ticketText,
          location: ticketLocation,
          errors: ticketsErrors,
          uploadFilesStore: uploadFilesStore,
        }} />
      </Box>

      <Box display='flex' justifyContent='flex-end' padding='0px 18px'>
        <Button variant='contained' onClick={handleControlledState}>Consultar</Button>
      </Box>

      <Box display='flex' justifyContent='space-between'>
        <Box display='flex' flexDirection='column' marginLeft='70px' justify-content='center'>
          {showErrors.text &&
            <Typography padding='0px 10px 5px' color='error' variant='subtitle2'> La consulta debe
              contener al menos 50 caracteres</Typography>}
          {showErrors.location &&
            <Typography padding='0px 10px 5px' color='error' variant='subtitle2'> Debe seleccionar una
              ubicación</Typography>}
        </Box>
      </Box>
    </Box>
  );
};
