import { useRegister } from '@components/layout/Register/context/Register.context';
import SocialRegister from '@components/layout/Register/SocialRegister.form';
import { Space } from '@components/Space/Space';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import { LoadingButton } from '@mui/lab';
import {Box, Button, CircularProgress, Divider, Link, Paper, TextField, Typography} from '@mui/material';
import { useAcoRouter } from '@providers/hooks/AcoRouter';
import { LoginStatus, useLogin } from '@providers/hooks/LoginHook';
import { CheckEmail } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import * as EmailValidator from 'email-validator';
import React, { useState } from 'react';

import styles from './Register.styles';

interface Props {
  onContinue: () => void;
  isModal?: boolean,
  setClose?: (boolean: boolean) => void,
}

export const RegisterForm: React.VFC<Props> = (props) => {
  const classes = styles();
  const router = useAcoRouter();

  const register = useRegister();
  const { stateLogin } = useLogin();

  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState<'ALREADY_EXISTS' | 'PENDING' | undefined>();

  const emailValid = !!register.data.email && EmailValidator.validate(register.data.email);

  const next = async () => {
    if (!emailValid) {
      return;
    }

    setLoading(true);

    axios
      .get<CheckEmail>('/api/user/check-email', { params: { email: register.data.email } })
      .then((res) => {
        if (res.data.alreadyExists) {
          setShowMessage(res.data.pending ? 'PENDING' : 'ALREADY_EXISTS');
        } else {
          props.onContinue();
        }
      })
      .catch((err) => {
        console.warn('Cannot check email for user', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper classes={{ root: `${classes.paper} ${props.isModal && 'modal'}` }}>
      <Box className={classes.boxTop}>
        <Box className={classes.boxLogin}>
          <Typography variant='h5'>Unite a la comunidad</Typography>
        </Box>

        <Box className={classes.boxBottom}>
          <Typography variant='body1'>¿Ya tenés cuenta?</Typography>
            <Button color='primary' disableRipple className={classes.register}
                    onClick={() => props.isModal ? props.setClose(true) : router.login()}>
              Iniciá sesión
            </Button>
        </Box>
      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <Box>
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <form autoComplete='off' className={classes.form}>
            <TextField
              required
              id='email'
              label='Email'
              margin='normal'
              variant='outlined'
              autoComplete='off'
              size='medium'
              autoFocus
              onChange={(e) => {
                setShowMessage(undefined);
                register.set('email', e.target.value);
              }}
              onKeyDown={(e) => e.key === 'Enter' && next()}
              className={clsx(classes.textFieldEmail, !!showMessage && classes.textFieldWithMessage)}
              inputProps={{
                className: classes.inputEmail,
                autoComplete: 'email',
                form: {
                  autoComplete: 'off',
                },
              }}
            />
            {showMessage && (
              <Box className={classes.inputCheckContainer}>
                <CheckIcon className={classes.inputCheck} />
              </Box>
            )}
          </form>

          {showMessage && (
            <Box display='flex' maxWidth='360px' alignItems='flex-start' width={'100%'} justifyContent='center'>
              <Box display='flex' justifyContent='center' alignItems='flex-start' paddingTop='2px'>
                <InfoIcon fontSize='small' className={classes.inputMessageIcon} />
              </Box>
              <Space vertical size={8} />
              <Box flexGrow={1}>
                <label className={classes.inputMessage}>
                  {showMessage === 'PENDING' && 'Enviamos un email a tu correo electronico para que actives tu cuenta'}
                  {showMessage === 'ALREADY_EXISTS' && (
                    <>
                      El email ya se encuentra registrado.{' '}
                      <b className={classes.loginMessageLink} onClick={() => router.login()}>
                        Inicia sesión
                      </b>
                    </>
                  )}
                </label>
              </Box>
            </Box>
          )}

          <Space size={10} />

          <Box display='flex' justifyContent='flex-end' className={classes.nextButtonContainer}>
            {stateLogin !== LoginStatus.LOADING ?
                <LoadingButton
                    loading={loading}
                    size='large'
                    disableRipple
                    classes={{root: classes.buttonLogin, disabled: classes.disabled}}
                    onClick={() => showMessage === 'ALREADY_EXISTS' ? router.login() : next()}
                    disabled={!emailValid && !showMessage}>
                  {!loading && 'Continuar'}
                </LoadingButton> :
                <Box className={classes.circularProgress}>
                  <CircularProgress />
                </Box>}
          </Box>

          <Space />

          <Box className={classes.infoTextContainer}>
            <Typography className={classes.infoText}>
              Al continuar, aceptás los{' '}
              <Link
                href='https://www.agroconsultasonline.com.ar/docs/tyc.pdf'
                target='_blank'
                className={classes.infoTextLink}
                underline="hover">
                Términos y condiciones de uso
              </Link>{' '}
              y las{' '}
              <Link
                href='https://www.agroconsultasonline.com.ar/docs/privacidad.pdf'
                target='_blank'
                className={classes.infoTextLink}
                underline="hover">
                Política de privacidad
              </Link>{' '}
              del sitio.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <Box marginTop='8px'>
        <Typography variant='body1' className={classes.boxTop}>
          o
        </Typography>
      </Box>

      <Box className={classes.boxTop}>
        <Box className={classes.boxConnect}>
          <Typography variant='body1'>Conectate con:</Typography>
        </Box>
        <Box>
          <SocialRegister onRegister={props.onContinue} />
        </Box>
      </Box>

    </Paper>
  );
};
