import Button from '@components/AcoButtons/Button';
import { IFormPassword } from '@components/layout/Login/RecoverPassword';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { Modal, ModalProps } from '@components/modal/Modal';
import { userRegisterEventModalStyles } from '@components/modal/OnlineEvents/RegisterEventModal.styles';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import { Box, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { RegisterEventStatus, useRegisterEvent } from '@providers/hooks/RegisterEventHook';
import { useIsMobile } from '@providers/Responsive.provider';
import { OnlineEvent, SingularEventType } from '@service/model';
import { GA } from '@shared/ga/ga';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import validator from 'validator';

export const SelfRegistrationEventModal: React.VFC<{ modalProps: ModalProps, onlineEvent: OnlineEvent }> = ({
                                                                                                              modalProps,
                                                                                                              onlineEvent,
                                                                                                            }) => {

  const classes = userRegisterEventModalStyles();

  const router = useRouter();
  const { stateRegisterEvent, registerForEvent, checkPaidEvent, paidEvent } = useRegisterEvent();
  const [loginOpen, setLoginOpen] = useState(false);
  const isMobile = useIsMobile();


  const INITIAL_FORM_INPUTS = {
    email: '',
  };

  const [values, setValues] = React.useState<IFormPassword>(INITIAL_FORM_INPUTS);

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');

    GA.event({ action: `close_modal_evento_${onlineEvent.id}`, category: 'modal', label: 'closeModal', value: 1 });

    if (router.query.oeid) {
      router.push(onlineEvent.categoryId == 3 ? '/trainings' : '/events');
    }
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const emailInvalid = (event) => {
    return !!validator.isEmail(event);
  };

  const handleChangeEmail = (event) => {
    setValues({ ['email']: event.target.value });
  };


  useEffect(() => {

    if (stateRegisterEvent === RegisterEventStatus.LOGGED && !paidEvent) {
      checkPaidEvent(values.email, onlineEvent.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRegisterEvent]);

  const modalRegisterEvent = (
    <>
      <Modal {...(modalProps as ModalProps)} onClose={handleClose}
             maxWidth='md' fullWidth fullScreen={isMobile}
             sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' } }}
             onClick={handleStopPropagation}
             title={onlineEvent.title}>
        <DialogContent className={classes.content}>

          {stateRegisterEvent === RegisterEventStatus.INITIAL &&
            <>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineEvent.description) }} />

              <Box className={classes.boxPago}>
                {onlineEvent.settings.amount &&
                  <Box display='flex' justifyContent='center' flexDirection={isMobile ? 'column' : 'unset'}>
                    <Typography
                      variant='subtitle1'>{onlineEvent.categoryId == 2 ? 'Este ' : 'Esta '} {SingularEventType[onlineEvent.categoryId]} tiene
                      un costo de ${onlineEvent.settings.amount} o USD {onlineEvent.settings.dolarAmount}: </Typography>

                    {onlineEvent.id != 349 && <Typography variant='subtitle1' style={{
                      color: '#5A9CCB',
                      marginLeft: isMobile ? '0px' : '4px',
                    }}>
                      <b> Para el acceso libre por 6 meses.</b>
                    </Typography>}


                  </Box>}


                <Typography variant='subtitle1'>Para ingresar debes ser usuario registrado de
                  Agroconsultas{onlineEvent.settings.amount && onlineEvent.id != 349 && ' y realizar el pago'}.</Typography>

                <Typography variant='subtitle1'>Si no estas registrado, registrate. Si estás registrado, iniciá
                  sesión{onlineEvent.settings.amount && (onlineEvent.id != 349 ? ' y realizá el pago' : ' y es GRATIS')}.</Typography>
              </Box>

              <Typography style={{ textAlign: 'center' }}>Ingresá tu mail para continuar</Typography>
            </>
          }

          {stateRegisterEvent === RegisterEventStatus.LOGGED &&
            <Box margin='20px 0px'>
              <Typography style={{ textAlign: 'center', margin: '10px 0px' }}> Tu email ya se encuentra registrado en
                Agroconsultas</Typography>

              <Typography
                style={{ textAlign: 'center' }}>Para {onlineEvent.settings.amount && !paidEvent && ' pagar e '}
                ingresar {onlineEvent.categoryId == 3 ? 'a la ' : 'al '} {SingularEventType[onlineEvent.categoryId]} solo
                debes <b>iniciar sesión.</b></Typography>


              <Typography style={{ textAlign: 'center' }}>También
                podes {onlineEvent.settings.amount && 'pagar e '} ingresar directamente desde el mail
                que te acabamos de enviar.</Typography>

              <Typography style={{ textAlign: 'center', marginTop: '10px' }}>Ante cualquier inconveniente escribinos
                a: <b><u><a
                  href='mailto:contacto@agroconsultasonline.com.ar'>contacto@agroconsultasonline.com.ar</a></u></b></Typography>

            </Box>}


          {stateRegisterEvent === RegisterEventStatus.ERROR &&
            <Box margin='20px 0px'>
              <Box display='flex' alignItems='center' justifyContent='center' style={{ margin: '10px 0px' }}>
                <Typography> Tu proceso de registro ya comenzó.</Typography>
                <ForwardToInboxOutlinedIcon fontSize='small' />
              </Box>

              <Typography style={{ textAlign: 'center' }}>Te enviamos un email a <b>{values.email}</b> para que lo
                finalices. </Typography>
              <Typography style={{ textAlign: 'center' }}>Hasta no finalizarlo no podrás ingresar.</Typography>

              <Typography style={{ textAlign: 'center', marginTop: '10px' }}>Ante cualquier inconveniente escribinos
                a: <b><u><a
                  href='mailto:contacto@agroconsultasonline.com.ar'>contacto@agroconsultasonline.com.ar</a></u></b></Typography>

            </Box>}


          {stateRegisterEvent != RegisterEventStatus.ERROR &&
            <form autoComplete='off' className={classes.form}>
              <Box className={classes.textInputContainer}>
                <TextField
                  required
                  autoFocus
                  id='email'
                  label='Email'
                  margin='normal'
                  variant='standard'
                  autoComplete='off'
                  size='medium'
                  onChange={(e) => handleChangeEmail(e)}
                  className={clsx(classes.textFieldEmail)}
                  inputProps={{
                    className: classes.inputEmail,
                    autoComplete: 'email',
                    form: {
                      autoComplete: 'off',
                    },
                  }} />
              </Box>
            </form>
          }
        </DialogContent>

        <DialogActions className={classes.actions}>
          {stateRegisterEvent === RegisterEventStatus.INITIAL &&
            <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                    onClick={() => registerForEvent(values.email, onlineEvent.id)}
                    disabled={!emailInvalid(values.email)}>
              Continuar
            </Button>}

          {stateRegisterEvent === RegisterEventStatus.LOGGED &&
            <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                    onClick={() => (
                      router.push({
                        query: { destination: `/events/${onlineEvent.id}` },
                      }, undefined, { shallow: true }) &&
                      setLoginOpen(true))}>
              Iniciar sesión
            </Button>}

          {stateRegisterEvent === RegisterEventStatus.ERROR &&
            <Button className={clsx('postButton')} color='primary' variant='outlined' size='large'
                    onClick={() => handleClose()}>
              Cerrar
            </Button>}
        </DialogActions>
      </Modal>
    </>
  );


  return (
    <>
      {modalRegisterEvent}
      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }} loginModalProps={{
        email: values.email,
      }} />}
    </>
  );
};
