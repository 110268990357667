import styles from '@components/Search/Search.styles';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputBase } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface EventSearchProps {
  placeholder: string;
  searchText?: (keyword: string) => void;
  value?: string;
}

const EventSearch: React.VFC<EventSearchProps> = (props) => {
  const classes = styles();
  const [keyword, setKeyword] = useState<string | undefined>(props.value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);

    if (event.target.value === '') {
      setKeyword(undefined);
      props.searchText && props.searchText(undefined);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const search = () => {
    if (props.searchText) {
      props.searchText(keyword);
    }
  };

  useEffect(() => {
    if (keyword?.length > 2) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    setKeyword(props.value);
  }, [props.value]);

  return (
    <div>
      <div className={classes.search}>
        <InputBase
          placeholder={props.placeholder}
          className={classes.input}
          type='search'
          value={keyword}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />

        <IconButton className={classes.searchIcon} onClick={search} size='large'>
          <SearchIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default EventSearch;
