import CommunityUserCard, { CommunityUserCardProps } from '@components/CommunityUserCard/CommunityUserCard';
import { ExpertsSkeleton } from '@components/Experts/ExpertsSkeleton';
import { COMMUNITY_TYPE } from '@components/forms/experts/ExpertsForm';
import { InfiniteScrolling } from '@components/InfiniteScrolling/InfiniteScrolling';
import { Box, Grid, Typography } from '@mui/material';
import { useNewTicketContext } from '@providers/ticket/NewTicketContext';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';

import { ExpertsState } from '../../pages/experts';

interface ExpertsProps {
  object?: CommunityUserCardProps[];
  initState?: ExpertsState;
  searchText: string;
  excludePaused?: boolean;
}

const UsersInfiniteScroll: React.VFC<ExpertsProps> = (props) => {

  const {
    updateExpert,
    handleNext,
    subcategory,
  } = useNewTicketContext();

  const [abortController, setAbortController] = useState<AbortController | null>(null);

  useEffect(() => {
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    return () => {
      newAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchText]);

  const render = (user) => {
    return (
      <Grid item xs={12} md={6}>
        <CommunityUserCard user={user}
                           userType={props.initState.type}
                           viewProps={{
                             onNewTicket: true,
                             onClick: updateExpert,
                             handleNext: handleNext,
                           }}
        />
      </Grid>
    );
  };

  const url = !!props.searchText ? '/api/experts/search' : (subcategory != null ? '/api/community/filterExperts/filterExperts' : `/api/community/${props.initState.type}`);
  const pageSizeQueryName = 'size';
  const pageSize = 16;
  const pageQueryName = 'page';

  const buildKey = (index: number) => {

    let newURL = `${url}?${pageSizeQueryName}=${pageSize}&${pageQueryName}=${index + 1}&communityType=${props.initState.type}&keyword=${props.searchText}`;

    if (!!props.searchText && props.excludePaused) {
      newURL += '&expertsTicket=true';
    }

    if (subcategory !== null) {
      newURL += `&subcategoryId=${subcategory.id}`;
    }

    return newURL;
  };

  const fetcher = (url: string) =>
    axios.get<{ users: CommunityUserCardProps[] }>(url, {
      signal: abortController?.signal,
    } as AxiosRequestConfig).then((res) => res.data.users);

  return (
    <InfiniteScrolling
      skeletonNode={<ExpertsSkeleton />}
      buildKey={buildKey}
      emptyNode={<Box marginTop='18px'>
        <Typography variant='subtitle1'>No
          hay {props.initState.type === COMMUNITY_TYPE.EXPERTS ? 'expertos' : 'empresas'} que respondan a esta temática,
          pero si tenemos
          {props.initState.type === COMMUNITY_TYPE.EXPERTS ? ' Empresas' : ' Expertos'} a disposición para responder tu
          consulta.</Typography>
      </Box>}
      fetcher={fetcher}
      renderItem={render}
      isModal={true}
      noMoreNode={<></>}
    />
  );
};

export default UsersInfiniteScroll;
