import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const userRegisterEventModalStyles = makeStyles((theme: Theme) =>
  createStyles({

    modal: {
      height: '120px',
    },

    content: {
      marginTop: 25,
      minWidth: 500,
      padding: 0,
      overflow: 'clip',

      [StyleResponsive.onMobile]: {
        minWidth: 'auto',
      },
    },
    actions: {
      padding: 0,
      marginTop: 15,
      '& > .postButton': {
        height: 48,
      },
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },
    },

    inputEmail: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    textFieldEmail: {
      [StyleResponsive.onDesktop]: {
        minWidth: '358px',
        margin: '0px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '266px',
        width: '90%',
      },
    },

    boxPago: {
      margin: '20px 0px',
      color: theme.palette.primary.main,

      [StyleResponsive.onDesktop]: {
        textAlign: 'center',
      },

    },

  }));
