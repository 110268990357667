import { a11yTabProps, Tab, Tabs } from '@components/ACOTabs';
import ErrorNode from '@components/common/ErrorNode';
import LoadingNode from '@components/common/LoadingNode';
import { CommunityUserCardProps } from '@components/CommunityUserCard/CommunityUserCard';
import BreadcrumbsThematic from '@components/Experts/BreadcrumbsThematic';
import UsersInfiniteScroll from '@components/Experts/UsersInfiniteScroll';
import Search from '@components/OnlineEvent/Search';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useNewTicketContext } from '@providers/ticket/NewTicketContext';
import axios from 'axios';
import React from 'react';
import useSWR from 'swr';

export enum COMMUNITY_TYPE {
  EXPERTS = 'experts',
  BUSINESS = 'business'
}

export interface ExpertsFormProps {
  type?: COMMUNITY_TYPE;
  consult?: boolean;
  withoutSearch?: boolean;
  notInteractable?: boolean;
}

interface ExpertsState {
  type: COMMUNITY_TYPE;
  key: number;
  filterFn: (CommunityUserCardProps) => boolean;
}

const ExpertsForm: React.FC<ExpertsFormProps> = (props) => {

  const experts = {
    type: COMMUNITY_TYPE.EXPERTS,
    key: 0,
  };

  const business = {
    type: COMMUNITY_TYPE.BUSINESS,
    key: 1,
  };

  const initState: ExpertsState = {
    type: props.type,
    key: props.type === COMMUNITY_TYPE.EXPERTS ? 0 : 1,
    filterFn: () => true,
  };

  const [state, setState] = React.useState<ExpertsState>(initState);
  const tabPositionOptions = [experts, business];
  const [searchText, setSearchText] = React.useState<string>(undefined);


  const { subcategory } = useNewTicketContext();

  const url = subcategory != null ? `/api/community/filterExperts/filterExperts?communityType=${state.type}&subcategoryId=${subcategory.id}` : `/api/community/${state.type}`;
  const fetcher = (url: string) => axios.get<{ data: CommunityUserCardProps[] }>(url).then((res) => res.data);
  const { data, error } = useSWR(url, fetcher);

  if (error) return <ErrorNode />;

  if (!data) return <LoadingNode />;

  const handleTabChange = (event: React.ChangeEvent, selectedIndex: number) => {
    setState({
      ...state,
      ...tabPositionOptions[selectedIndex],
    });
  };

  const searchExperts = (text) => {
    setSearchText(text);
  };


  return (
    <Box height='100%'>
      <TabContext value={state.type}>
        <Tabs value={state.key} onChange={handleTabChange} variant='fullWidth' indicatorColor='primary'
              textColor='primary' style={{ marginBottom: '8px' }}>
          <Tab value={experts.key} label='Expertos' disableRipple
               icon={<PeopleOutlineOutlinedIcon />} {...a11yTabProps(experts.key)} />
          <Tab value={business.key} label='Empresas' disableRipple
               icon={<StoreMallDirectoryOutlinedIcon />} {...a11yTabProps(business.key)} />
        </Tabs>

        {props.consult === false && state.type === COMMUNITY_TYPE.EXPERTS ?
          <>
            <Typography>No tiene mas consultas mensuales</Typography>
          </> :
          <Box height='90%'>

            {!props.withoutSearch &&
              <Search placeholder={'Buscar experto a consultar por nombre, temática o especialidad'}
                     searchText={searchExperts} />}

            <Box style={{ paddingBottom: '10px' }}>
              <TabPanel value={experts.type} style={{ padding: '0px' }}>
                <BreadcrumbsThematic notInteractable={props.notInteractable}/>
                <UsersInfiniteScroll initState={state} searchText={searchText} excludePaused />
              </TabPanel>
              <TabPanel value={business.type} style={{ padding: '0px' }}>
                <BreadcrumbsThematic notInteractable={props.notInteractable}/>
                <UsersInfiniteScroll initState={state} searchText={searchText} />
              </TabPanel>
            </Box>


          </Box>
        }
      </TabContext>
    </Box>


  );
};

export default ExpertsForm;
