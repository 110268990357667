import ACOTooltipProfile from '@components/ACOTooltip/ACOTooltipProfile';
import { Avatar } from '@components/Avatar/Avatar';
import RenderThematics from '@components/CommunityUserCard/RenderThematics';
import { ACOEmojiFlag } from '@components/Flag/ACOFlag';
import { COMMUNITY_TYPE } from '@components/forms/experts/ExpertsForm';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { VerifiedUserOutlined } from '@mui/icons-material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Box, Card, Typography } from '@mui/material';
import { OnDesktop, OnMobile, useIsDesktop } from '@providers/Responsive.provider';
import { ObjectType, ThematicExpert } from '@service/model';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';


import styles from './CommunityUserCard.styles';


export interface Category {
  category: string;
  subcategory?: string;
}

export interface CommunityUserCardProps {
  name: string;
  lastname?: string;
  completeName?: string;
  img?: string;
  nickname: string;
  verified?: boolean;
  countries?: string[];
  url: string;
  id: number;
  stats: {
    ticketsAnswered: number;
    usefulReceived?: number;
    usersInterested?: number;
    responseTime?: string;
    responsePercentage?: number;
  };
  categories?: Category[];
  thematics?: ThematicExpert[];
  state?: string;
  observations?: string;
  description?: string;
  vip?: boolean;
  consulting_type?: string;
  active?: boolean;
}

interface ViewProps {
  onNewTicket: boolean;
  onClick?: (communityUserCard: CommunityUserCardProps) => void;
  handleNext?: () => void;
}

function renderCountries(countries: string[], className: string) {
  if (!countries || countries.length <= 0) return;

  return (
    <Box className={className}>
      {
        countries.map((country, index) => {
          //return (<ACOFlag key={index} country={country}/>);
          return (<ACOEmojiFlag key={index} country={country} />);
        })
      }
    </Box>
  );
}

const CommunityUserCard: React.FC<{
  user: CommunityUserCardProps,
  userType?: COMMUNITY_TYPE,
  viewProps?: ViewProps
}> = ({ user, userType, viewProps }) => {
  const classes = styles();

  const router = useRouter();
  const redirectUrl = user.url;
  const redirectClick = redirectUrl ? (() => router.push(redirectUrl)) : undefined;
  const isDesktop = useIsDesktop();
  const userId = user.id;
  const [loginOpen, setLoginOpen] = useState(false);

  const selectExpert = () => {
    viewProps.onClick(user);
    viewProps.handleNext();
  };

  return (
    <Card className={clsx(classes.root, { clickable: isDesktop })}
          onClick={isDesktop || viewProps.onClick ? () => viewProps.onClick ? selectExpert() : redirectClick() : null}>
      <a href={(!viewProps.onClick) ? redirectUrl : undefined}>

        <div className='container'>
          <Box display='flex' flexDirection='column' className={classes.content}>
            <Box display='flex' flexDirection='row' justifyContent='flex-start'>
              <Avatar src={user.img} alt={userType == COMMUNITY_TYPE.EXPERTS ? user.completeName : user.name} size={{ desktop: 115, mobile: 48 }} />
              <Box marginLeft='15px' width='0' />
              <Box display='flex' flexDirection='column' justifyContent='space-between' width='100%'>
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                  <Box flexGrow={1}>
                    <ACOTooltipProfile profile={user} objectType={userType == COMMUNITY_TYPE.EXPERTS ? ObjectType.USER : ObjectType.BUSINESS}>
                    <Typography variant='subtitle1'>
                      {userType == COMMUNITY_TYPE.EXPERTS ? user.completeName : user.name} <VerifiedUserOutlined className={classes.verified} />
                    </Typography>
                  </ACOTooltipProfile>
                  </Box>
                  {renderCountries(user.countries, classes.flag)}
                </Box>
                <Typography variant='body2' color='textSecondary'>@{user.nickname}</Typography>


              <OnDesktop>
                <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                         component='span'>{user.stats.ticketsAnswered}</Typography> Consulta{user.stats.ticketsAnswered == 1 ? ' ' : 's '}
                  respondida{user.stats.ticketsAnswered == 1 ? ' ' : 's '} (<Typography variant='subtitle1'
                                                                                        component='span'>{user.stats.ticketsAnswered == 0 ? '0' : user.stats.responsePercentage}%</Typography>)
                </Typography>
                {user.stats.usefulReceived >= 0 &&
                  <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                           component='span'>{user.stats.usefulReceived}</Typography> Útiles
                    recibidos</Typography>
                }
                {user.stats.usersInterested >= 0 &&
                  <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                           component='span'>{user.stats.usersInterested}</Typography> Seguidores</Typography>
                }
                <Typography variant='body1' component='span'> <Typography variant='subtitle1'
                                                                          component='span'>{user.stats.ticketsAnswered == 0 ? '0' : user.stats.responseTime}</Typography> Tiempo
                  de respuesta promedio</Typography>


              </OnDesktop>
            </Box>
          </Box>
          <OnMobile>
            <Box display='flex' flexDirection='column' justifyContent='space-between' alignContent='center'
                 marginTop='5px'>
              {user.stats.usefulReceived >= 0 &&
                <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                         component='span'>{user.stats.usefulReceived}</Typography> Útiles
                  recibidos</Typography>
              }
              {user.stats.usersInterested >= 0 &&
                <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                         component='span'>{user.stats.usersInterested}</Typography> Seguidores</Typography>
              }
              <Typography variant='body1' component='span'><Typography variant='subtitle1'
                                                                       component='span'>{user.stats.ticketsAnswered}</Typography> Consulta{user.stats.ticketsAnswered == 1 ? ' ' : 's '}
                respondida{user.stats.ticketsAnswered == 1 ? ' ' : 's '} (<Typography variant='subtitle1'
                                                                                      component='span'>{user.stats.ticketsAnswered == 0 ? '0' : user.stats.responsePercentage}%</Typography>)
              </Typography>
              <Typography variant='body1' component='span'> <Typography variant='subtitle1'
                                                                        component='span'>{user.stats.ticketsAnswered == 0 ? '0' : user.stats.responseTime}</Typography> Tiempo
                de respuesta promedio</Typography>
            </Box>
          </OnMobile>
          <Box display='flex' className={classes.categoriesBox} flexDirection='column' justifyContent='flex-start'
               alignContent='flex-start' flexWrap='wrap'>
            {user.state === 'Inactivo' &&
              <Typography variant='body2' color='textSecondary' display='flex'> <ErrorOutlineOutlinedIcon fontSize='small' style={{
                color: '#828282',
                verticalAlign: 'text-bottom',
                marginRight: '2px',
                }} /> Por el momento el experto no responde consultas</Typography>}

              {user.observations && <Typography variant='body2' style={{
                background: '#EEEEEE',
                padding: '6px',
              }}>{user.observations}</Typography>}

              {/*<Typography variant='body2' color='textSecondary' style={{ marginTop: 5 }}>Responde sobre:</Typography>*/}
              <Box display='flex' flexDirection='row' flexWrap='wrap' width='100%'>
                <RenderThematics thematics={user.thematics} onNewTicket={viewProps.onNewTicket}
                                 user={{ id: userId, type: userType, state: user.state, url: user.url }} />
              </Box>
            </Box>
          </Box>
        </div>
        {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }} />}
      </a>
    </Card>

  );
};

CommunityUserCard.defaultProps = {
  viewProps: {
    onNewTicket: false,
  },
};

export default CommunityUserCard;
