import styles from '@components/Search/Search.styles';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Chip, Typography } from '@mui/material';
import { OnDesktop } from '@providers/Responsive.provider';
import { useNewTicketContext } from '@providers/ticket/NewTicketContext';
import React from 'react';

const BreadcrumbsThematic: React.VFC<{ notInteractable?: boolean }> = (props) => {
  const classes = styles();

  const {
    thematic,
    expert,
    updateStepIndex,
    updateThematic,
    updateThematicID,
    completeThematic,
    updateCompleteThematic,
    subcategory,
  } = useNewTicketContext();

  const lengthCompleteName = subcategory ? subcategory.completeName.length : 1;

  let thematicAux = completeThematic;

  const handleUpdateThematic = (newThematicId: number, parent: { id: number, name: string }[]) => {
    updateStepIndex(1);

    if (expert === null) {
      parent.map((children, index) => {
        if (children.id === newThematicId) {
          index === 0 && updateThematic(null);
          updateThematicID(index === 0 ? null : parent[index - 1].id);
        }
      });
    } else {

      let index = 0;

      while (index < parent.length) {
        {
          thematicAux?.childrens && thematicAux.childrens.map(children => {

            if (index === 0 && newThematicId === parent[index].id) {
              updateThematic(null);
              updateCompleteThematic(null);
              index = completeThematic.completeName.length;
            } else if (parent[index].id === children.id) {
              thematicAux.childrens.map(item => {
                if (newThematicId === item.id) {
                  updateThematic(thematicAux);
                  index = completeThematic.completeName.length;
                }
              });

              thematicAux = children;
            }
          });
        }

        index += 1;
      }
    }
  };

  return (
    <Box marginBottom='10px'>
      {subcategory != null &&
        <Box display='flex' alignItems='center'>
          <Typography color='textSecondary'>Responden a: </Typography>
          <Box display='flex' alignItems='center' marginTop='8px' overflow='auto' minHeight='25px'>
            {subcategory.completeName.map((item, index: number) =>
              (<Box display='flex' alignItems='center' key={index}>
                  <Chip size='small' label={item.name}
                        deleteIcon={(index === (lengthCompleteName - 1) && !props.notInteractable) ? <HighlightOffIcon /> : null}
                        onDelete={() => (index === (lengthCompleteName - 1) && !props.notInteractable) ? handleUpdateThematic(item.id, thematic.completeName) : null}
                        className={`${classes.chipTag} ${(index < (lengthCompleteName - 1) && props.notInteractable) ? 'noSelect' : ''}`} />


                  {index < (lengthCompleteName - 1) &&
                    <OnDesktop>
                      <ArrowForwardIosOutlinedIcon fontSize='small' style={{ color: '#26A65B' }} />
                    </OnDesktop>
                  }
                </Box>
              ),
            )}
          </Box>
        </Box>}
    </Box>

  );
};

export default BreadcrumbsThematic;
