import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    border: '1px solid #DCDCDC',
    boxShadow: '0px 2px 6px #DCDCDC',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      overflow: 'visible',
      borderRadius: 8,
      '& .container': {
        borderRadius: 8,
        boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
      },
      cursor: 'pointer',
    },
  },
  content: {
    padding: 12,
  },
  categoriesBox: {
    [StyleResponsive.onDesktop]: {
      marginTop: 5,
    },
  },
  chip: {
    marginTop: 5,
    marginLeft: 5,
    borderRadius: 6,
    backgroundColor: 'rgba(38, 166, 91, 0.1)',
    color: theme.palette.primary.dark,
    height: '100%',
    '& .MuiChip-label': {
      whiteSpace: 'break-spaces',
    },
    [StyleResponsive.onMobile]: {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      '& .MuiChip-label': {
        padding: '0 0 0 3px',
      },
    },
    '&.main': {
      fontWeight: 'bold',
    },
  },
  verified: {
    marginLeft: 15,
    fontSize: 22,
    color: theme.palette.primary.dark,
    verticalAlign: 'text-bottom',
  },
  flag: {
    textAlign: 'right',
    fontSize: 14,
    display: 'flex',
    marginLeft: 5,
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 20px',
    backgroundColor: '#FFFFFF',
    [StyleResponsive.onDesktop]: {
      width: '434px',
    },
    [StyleResponsive.onMobile]: {
      width: '93vw',
    },
  },
  boxPopover: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 2px 12px 0px rgba(220, 220, 220, 1)',
    },
  },

  tooltip: {
    backgroundColor: theme.palette.background.paper,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    padding: '10px 15px',
    boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: '8px',
    minWidth: '160px',
  },
  arrow: {
    color: theme.palette.background.paper,
    '&:before': {
      boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.5)}`,
    },
  },
}));
