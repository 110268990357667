import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';


export default makeStyles((theme: Theme) =>
  createStyles({

    cardEvent: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',


      [StyleResponsive.onDesktop]: {
        marginTop: '20px',


      },

      [StyleResponsive.onMobile]: {
        minHeight: '410px',
        width: '100%',
      },
    },

    boxInfo: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',
      padding: '20px',

      [StyleResponsive.onDesktop]: {
        margin: '20px 0px',
        width: '100%',
      },

      [StyleResponsive.onMobile]: {
        margin: '20px 0px',
        width: '100%',
      },
    },

    boxRegistro: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',


      [StyleResponsive.onDesktop]: {
        margin: '10px 0px',
        width: '100%',
      },

      [StyleResponsive.onMobile]: {
        width: '100%',
        margin: '5px 0px',
      },
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '5px 20px 20px',
    },

    textInputContainer: {
      width: '100%',
      '& > div': {
        width: '100%',
        margin: '0px',

        [StyleResponsive.onMobile]: {
          margin: '0px',
        },
      },
    },

    textfield: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    boxInputs: {
      display: 'flex',
      alignItems: 'center',

      [StyleResponsive.onMobile]: {
        margin: '10px 0px',
      },
    },

    inputPassword: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },


    boxTitle: {
      background: theme.palette.primary.dark,
      color: 'white',
      width: '100%',
      textAlign: 'center',
      borderRadius: '4px',
    },


    formPago: {
      boxShadow: '0px 2px 6px #dcdcdc',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      marginBottom: '20px',

      [StyleResponsive.onDesktop]: {
        padding: '30px 100px',
      },

      [StyleResponsive.onMobile]: {
        padding: '20px 25px',
        width: '100%',
      },

    },


    formCCDate: {
      border: 'none',
      fontFamily: '-apple-system, Rubik, sans-serif',
      fontSize: '14px',
      borderBottom: '1px solid rgba(128, 128, 128, 0.81)',
      borderRadius: '0px',
      margin: '0px 10px',
      height: '28px',
      width: '100%',
    },


    select: {
      border: 'none',
      borderBottom: '1px solid grey',
      background: '#FFFFFF',
      minWidth: '140px',

      [StyleResponsive.onMobile]: {
        margin: '10px 0px',
        width: '100%',
      },

    },


    dateTextField: {
      '& .MuiOutlinedInput-input': {
        fontSize: '14px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& .MuiFormLabel-root': {
        paddingTop: '-2px',
        fontSize: '14px',
        color: '#828282',
        '& > .MuiOutlinedInput-root > fieldset > legend': {
          fontSize: '11px',
        },
      },
    },


    textFieldSecondary: {
      // Override TextField color
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.primary.main,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.primary.main,
        },
      },
    },


  }),
);
